export const tableColumn = [
	{
		label: '排名',
		prop: 'rankNo',
		width: '100px',
		isCustomize: true,
		tableType: 'no',
	},
	{
		label: '姓名',
		prop: 'username',
		// width: '120px',
		
	},
	{
		label: '上级部门',
		prop: 'preDeptName',
	},
	{
		label: '部门',
		prop: 'deptName',
	},
	{
		label: '业绩(元)',
		prop: 'amount',
	},
	// {
	// 	label: '入职日期',
	// 	prop: 'hiredate',
	// },
]
